import React, { useEffect, useState } from 'react';
import {
  getAccountDetails,
  getAccountInfo,
  getAccountArticlesDetails,
} from '../../utils/users/account';
import accountConfig from '../../config/account';
import utils from '../../utils/product/product-utils';
import Loader from '../../components/loader/loaderComponent';
import Title from '../../components/title-tile/titleTileComponent';
import ArticlesModal from './articlesModal';

const AccountNamesMap = [
  { id: accountConfig.account1, name: 'Facturas adeudadas' },
  { id: accountConfig.account2, name: 'Pedidos' },
];

const DetailsRows = {
  form: ' Formulario',
  number: ' numero',
  date: ' fecha',
  dueDate: ' vto',
  invoice: ' Comprobante_ID\r',
};

const AccountDisplay = (props) => {
  const { accountName, balance } = props;
  const nameChosen = AccountNamesMap.filter((elem) => elem.id === accountName);
  const className = (amount) => {
    if (amount < 0) {
      return 'alert alert-success';
    } else if (amount > 0) {
      return 'alert alert-danger';
    }
    return 'alert alert-dark';
  };
  const money = `${
    utils.formatCurrency(Math.abs(balance)) || 'No hay informacion'
  }`;
  const accountNameChosen = (amount) => {
    // Pedidos has other names
    if (nameChosen[0].name === 'Pedidos') {
      return `${nameChosen[0].name} sin Retirar`;
    } else {
      if (amount < 0) {
        return `Saldo a favor en ${nameChosen[0].name}`;
      } else if (amount > 0) {
        return `Saldo en ${nameChosen[0].name}`;
      }
    }
    return `${nameChosen[0].name}`;
  };
  return (
    <div className={className(balance)} role="alert">
      <h4 className="alert-heading">{accountNameChosen(balance)}</h4>
      <hr></hr>
      <p className="mb-0">{money}</p>
    </div>
  );
};

const AccountDisplayDetails = (props) => {
  const { accountName, data } = props;
  const [articles, setArticles] = useState([]);
  const [currentTotal, setCurrentTotal] = useState(0);

  const getKey = () => {
    if (accountName === accountConfig.account1) {
      return ' C1';
    } else {
      return ' C2';
    }
  };

  const getDetails = () => {
    const details = [];
    const isC2 = accountName === accountConfig.account2;
    for (let i = 0; i < data.length; i++) {
      const value = data[i][getKey()];
      if (parseFloat(value) !== 0) {
        details.push(
          <tr key={data[i][DetailsRows.number]}>
            {!isC2 ? <td>{data[i][DetailsRows.form]}</td> : null}
            <td>{data[i][DetailsRows.number]}</td>
            <td>{data[i][DetailsRows.date]}</td>
            <td>{data[i][DetailsRows.dueDate]}</td>
            <td className={'text-right'}>{utils.formatCurrency(value)}</td>
            <td className="text-center">
              <button onClick={() => showModal(data[i][DetailsRows.invoice], value)}>
                Ver Detalle
              </button>
            </td>
          </tr>
        );
      }
    }
    return details;
  };

  const showModal = async (invoice, total) => {
    const details = await getAccountArticlesDetails(invoice);
    setArticles(details);
    setCurrentTotal(total);
    props.setShowModal(true);
  };

  const getHeaders = () => {
    const headers = [];
    const keys = Object.keys(DetailsRows);
    const isC2 = accountName === accountConfig.account2;
    for (let i = 0; i < keys.length; i++) {
      // We skip the form column for C2
      if (
        (isC2 && DetailsRows[keys[i]] === DetailsRows.form) ||
        keys[i] === 'invoice'
      ) {
        continue;
      } else {
        headers.push(
          <th className="text-center" key={`col-header-${i}`} scope="col">
            {DetailsRows[keys[i]].toUpperCase()}
          </th>
        );
      }
    }
    headers.push(
      <th className="text-center" key={`col-header-saldo`} scope="col">
        {isC2 ? 'IMPORTE' : 'SALDO'}
      </th>
    );
    headers.push(
      <th className="text-center" key={`col-header-invoice`} scope="col">
        {'DETALLE'}
      </th>
    );

    return headers;
  };

  return (
    <>
      <>
        <table className="table table-sm table-responsive table-hover table-bordered table-custom">
          <thead className="thead-light">
            <tr>{getHeaders()}</tr>
          </thead>
          <tbody>{data && getDetails()}</tbody>
        </table>
      </>
      <>
        {articles.length > 0 && (
          <ArticlesModal
            setShow={props.setShowModal}
            show={props.showModal}
            articles={articles}
            currentTotal={currentTotal}
          ></ArticlesModal>
        )}
      </>
    </>
  );
};

const AccountInfo = (props) => {
  const [accountData, setAccountData] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [accountDataLoaded, setAccountDataLoaded] = useState(false);
  const [showNoInformation, setShowNoInformation] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function getUserAccounts() {
      const account = await getAccountInfo(props.user.internalId);
      return account;
    }
    async function getUserAccountDetails() {
      const details = await getAccountDetails(props.user.internalId);
      return details;
    }
    getUserAccounts().then((res) => {
      if (res && res.length > 0) {
        setAccountData(res[0]);
        getUserAccountDetails().then((details) => {
          if (res && res.length > 0) {
            setAccountDetails(details);
          }
          setAccountDataLoaded(true);
        });
      } else if (res.length === 0) {
        setShowNoInformation(true);
        setAccountDataLoaded(true);
      }
    });
  }, [props.user]);

  return (
    <div>
      {!accountDataLoaded && <Loader show={!accountDataLoaded}></Loader>}
      {accountData && accountDataLoaded && (
        <>
          <p>{`Saldos de caja al dia: ${utils.formatDate(Date.now())}`}</p>
          <>
            <AccountDisplay
              balance={accountData[accountConfig.account1]}
              accountName={accountConfig.account1}
            ></AccountDisplay>
            {accountDetails && (
              <AccountDisplayDetails
                accountName={accountConfig.account1}
                data={accountDetails}
                setShowModal={setShowModal}
                showModal={showModal}
              ></AccountDisplayDetails>
            )}
          </>
          <>
            <AccountDisplay
              balance={accountData[accountConfig.account2]}
              accountName={accountConfig.account2}
            ></AccountDisplay>
            {accountDetails && (
              <AccountDisplayDetails
                accountName={accountConfig.account2}
                data={accountDetails}
                setShowModal={setShowModal}
                showModal={showModal}
              ></AccountDisplayDetails>
            )}
          </>
        </>
      )}
      {showNoInformation && (
        <Title
          theme="dark"
          message="No hay información disponible"
          hide={!showNoInformation}
        ></Title>
      )}
    </div>
  );
};

export default AccountInfo;
